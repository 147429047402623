import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import RRSS from "../../../static/images/icons/web/icon32.svg"

const Soluciones4 = () => {
  const data = useStaticQuery(graphql`
    query Images_rrss {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Gestión de Redes Sociales Kit Digital | Qualoom</title>
        <meta name="title" content="Gestión de Redes Sociales Kit Digital | Qualoom"/>
        <meta name="description" content="Soluciones del Kit Digital en la gestión de redes sociales para empresas (Facebook, Twitter, LinkedIn, Instagram...) ¡Infórmate y consúltanos!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/redes-sociales/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/redes-sociales/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/redes-sociales/"/>
        <meta property="og:title" content="Gestión de Redes Sociales Kit Digital | Qualoom"/>
        <meta property="og:description" content="Soluciones del Kit Digital en la gestión de redes sociales para empresas (Facebook, Twitter, LinkedIn, Instagram...) ¡Infórmate y consúltanos!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/redes-sociales/"/>
        <meta property="twitter:title" content="Gestión de Redes Sociales Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Soluciones del Kit Digital en la gestión de redes sociales para empresas (Facebook, Twitter, LinkedIn, Instagram...) ¡Infórmate y consúltanos!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/redes-sociales/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}},
              {"@type":"ListItem", "position":"3", "name":"Gestión de Redes Sociales", "item": {"@id": "https://www.qualoom.es/kit-digital/redes-sociales/", "name": "Gestión de Redes Sociales"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soluciones en Redes Sociales</h1>
              <p>Soluciones para promocionar tu empresa en las distintas redes sociales.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Gestión de Redes Sociales</h2>
          <div className="soluciones-info">
            <img src={RRSS} alt=""></img>
            <p><b>Objetivo principal:</b> Promocionar a las empresas beneficiarias en redes sociales, como Facebook, Twitter, Instagram, etc.</p>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Servicios ofrecidos</p></div>
            <div className="row">
              <div className="normal-cell-1">
              <p><b>Social Media Plan:</b> contarás con una estrategia de redes sociales alineada con tu misión y visión, para que sea relevante y conecte con tus potenciales clientes y fidelice a aquellos usuarios que ya lo sean.</p>
              <p><b>Monitorización de redes sociales:</b> tendrás monitorizados y controlados de manera periódica los impactos de las acciones en redes sociales para ver tus resultados y saber si estás cumpliendo con los objetivos de tu estrategia.</p>
              <p><b>Optimización de la red/Auditoría Social Media:</b> te ayudará a optimizar el rendimiento, analizando diferentes canales sociales.</p>
              <p><b>Gestión de una red social:</b> el Agente Digitalizador que hayas seleccionado para proporcionarte la solución te administrará el perfil/usuario en, al menos, una red social.</p>
              <p><b>Publicación de posts semanales:</b> el Agente Digitalizador publicará un mínimo de 4-8 entradas (posts) mensuales en tus redes sociales.</p>
              </div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Porcentajes de ejecución por fases</p></div>
            <div className="row">
              <div className="normal-cell-2"><p>Primera fase 70%</p></div>
              <div className="normal-cell-2"><p>Segunda fase 30%</p></div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Importe máximo del Bono por segmento</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>Segmento I<br/>2.500€</p></div>
              <div className="normal-cell-3"><p>Segmento II<br/>2.500€</p></div>
              <div className="normal-cell-3"><p>Segmento III<br/>2.000€</p></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soluciones4